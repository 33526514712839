<template>
    <zw-sidebar @shown="shown" :title="$t('stock.title.stock_push')">
        <validation-observer tag="div" ref="observer">
            <b-overlay :show="loading" no-wrap></b-overlay>
            <div>
                <b-row>
                    <b-col cols="12">
                        <zw-input-group v-model="form.count"
                                        name="count"
                                        :label-prefix="labelPrefix"
                                        type="number"
                                        validate="required"
                        ></zw-input-group>
                    </b-col>
                    <b-col cols="12">
                        <zw-input-group v-model="form.description"
                                        name="description"
                                        :label-prefix="labelPrefix"
                        ></zw-input-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col sm="12" class="text-xs-center">
                        <b-button block @click="onSubmit()" variant="primary">
                            {{ $t('common.button.save') }}
                        </b-button>
                    </b-col>
                </b-row>
            </div>
        </validation-observer>
    </zw-sidebar>
</template>

<script>
import {mapGetters} from 'vuex'
import commonSave from '@/bundles/erika_common_bundle/mixins/common-save'

export default {
    name: 'StockPushModal',
    mixins: [commonSave],
    data() {
        return {
            loading: true,
            payload: {},
            callback: null,
            defaultForm: {},
            form: {},
            maxPossible: 0,
            labelPrefix: 'stock.label.supply.',
        }
    },
    methods: {
        shown() {
            this.form.to = this.payload.stock_id
            this.loading = false
        },
        onSubmit() {
            this.commonOnSubmit('Supply/stockPush')
        },
    }
}
</script>